exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-chi-mi-rivolgo-js": () => import("./../../../src/pages/a-chi-mi-rivolgo.js" /* webpackChunkName: "component---src-pages-a-chi-mi-rivolgo-js" */),
  "component---src-pages-analisi-transazionale-js": () => import("./../../../src/pages/analisi-transazionale.js" /* webpackChunkName: "component---src-pages-analisi-transazionale-js" */),
  "component---src-pages-chi-sono-js": () => import("./../../../src/pages/chi-sono.js" /* webpackChunkName: "component---src-pages-chi-sono-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

